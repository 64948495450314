<!--
 * @Autor: syq
 * @Date: 2021-07-12 19:23:20
-->
<template>
  <div id="Aftermarket">
    <div>
      <van-field
        v-model="value"
        label="货物状态"
        placeholder="请选择货物签收状态"
      />
      <van-field
        v-model="value"
        label="售后数量"
        placeholder="明细固定数量，无法修改"
      >
        <template #button>
          <van-button size="small" type="default">1</van-button>
        </template>
      </van-field>
      <van-field
        clickable
        name="picker"
        label="售后原因"
        placeholder="请选择退款原因"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <!-- <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        /> -->
        <Salemessage />
      </van-popup>
      <van-field
        v-model="message"
        rows="2"
        autosize
        label="售后说明"
        type="textarea"
        placeholder="请输入售后详细描述"
      />
      <van-field name="uploader" label="文件上传">
        <template #input>
          <van-uploader v-model="uploader" />
        </template>
      </van-field>
    </div>
    <div class="quit">
      <button class="btn">提交</button>
    </div>
  </div>
</template>

<script>
import Salemessage from "./components/salemessage.vue";

export default {
  components: { Salemessage },
  data() {
    return {
      value: "",
      showPicker: false,
      uploader: [{ url: "https://img01.yzcdn.cn/vant/leaf.jpg" }],
    };
  },
};
</script>

<style lang="less">
#Aftermarket {
  .quit {
    display: flex;
    align-items: center;
    margin-top: 280px;
    .btn {
      width: 96%;
      border: 0;
      margin: 0 auto;
      font-size: 16px;
      height: 38px;
      line-height: 20px;
      border-radius: 7px;
      color: #fff;
      background-color: rgba(2, 167, 240, 1);
    }
  }
}
</style>
