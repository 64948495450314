<template>
  <div id="remessage">
    <div class="collar-head">
      <p class="collar-floor">售后原因</p>
      <p class="collar-title">请选择原因</p>
    </div>
    <div class="choose">
      <van-radio-group v-model="result">
        <van-radio name="1">质量问题</van-radio>
        <van-radio name="2">少发漏发 </van-radio>
        <van-radio name="3">与商品描述不符</van-radio>
        <van-radio name="4">购买后降价了</van-radio>
        <van-radio name="5">买多买错</van-radio>
        <van-radio name="6">其他</van-radio>
      </van-radio-group>
    </div>
    <div class="quit">
      <button class="btn" @click="remove">取消</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      result: ["1", "2", "3", "4", "5", "6"],
    };
  },
  methods: {
    remove() {
      this.msg = false;
    },
  },
};
</script>

<style lang="less">
#remessage {
  width: 100%;
  line-height: 10px;
  background-color: #f7f8fa;
  .collar-head {
    display: flex;
    font-size: 16px;
    margin-bottom: -8px;
  }
  .collar-floor {
    margin-left: 12px;
  }
  .collar-title {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 13px;
    font-family: "微软雅黑", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #555555;
    text-align: left;
  }
  .choose {
    background-color: rgba(255, 255, 255, 1);
    margin-top: 5px;
    .van-radio {
      width: 100%;
      padding: 0 12px 2px;
      height: 48px;
      border: 1px solid rgba(242, 242, 242, 1);
      font-family: "微软雅黑";
      font-weight: 400;
      font-style: normal;
      color: #7f7f7f;
      line-height: 22px;
      font-size: 13px;
    }
    .van-radio__icon {
      font-size: 16px;
    }
  }
  .quit {
    .btn {
      margin-top: 10px;
      display: block;
      width: 100%;
      padding: 0;
      font-size: 16px;
      line-height: 44px;
      text-align: center;
      background: #fff;
      border: none;
    }
  }
}
</style>
